<template>
  <Navbar :user="user" />
    <div class="container pb-100 pt-100" v-if="!isLoading">
      <div class="section-title">
        <h2>Add A new Chapter</h2>
      </div>
      <div class="contact-form">
        <form id="contactForm" @submit.prevent="addChapter">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
              <div class="form-group text-start">
                <label for="name" class="text-start">Title</label>
                <input type="text" v-model="form.chapter" class="form-control"/>
                <div v-if="errors.chapter" class="text-small text-danger text-start">{{ errors.chapter[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Description</label>
                <textarea v-model="form.description" class="form-control" cols="30" rows="5"></textarea>
                <div v-if="errors.description" class="text-small text-danger text-start">{{ errors.description[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
             <button type="submit" class="btn default-btn" disabled v-if="submitting">
                <span class="label">{{ value }}</span>
              </button>
              <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
                <span class="label">Create Chapter</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import axios from 'axios'
import Loading from '@/components/Loading.vue';
export default {
  components: { Navbar,Loading },
  data(){
    return {
      isLoading: true,
      submitting: false,
      token: localStorage.getItem('user_data'),
      form:{
        chapter:'', description:"", course_id: this.$route.params.id
      },
      user: {},
      errors: {}
    }
  },
  methods:{
    addChapter(){
      this.submitting = true
      this.value = 'Please Wait...'
      let id = this.$route.params.id
      axios.post('https://apitraining.vipawaworks.com/api/course_content/store',this.form).then(response =>{
        response.data
        this.$router.push({ name: 'CourseDashboard', params: {id: id}})
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.submitting = false
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Chpater - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
  },

};
</script>

<style>
</style>